.definition-row {
  line-height: 1.2;
}

.definition-area {
  padding-bottom: 0;
}

.definition-area .domain {
  text-transform: uppercase;
}

.definition-area,
.matches{
  padding-bottom: 12px;
}

.definition-sources {
  font-size: 12px;
  font-weight: 400;
  color: #6b7885;
  .source-link{
    font-size: 12px;
    color: #6b7885;
    text-align: left;
    word-break: break-all;
  }
}

.ul-list-style{
  margin-bottom: 12px;
  list-style: disc;
}
