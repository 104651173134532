.search-wrap {
  position: sticky;
  top: 70px;
  z-index: 111;
  width: 100%;
}

.search-panel {
  padding-left: 16px;
  @include media-breakpoint-up(md) {
    padding: 0 36px;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 124px;
  }
}

.main-search {
  position: relative;
}

.main-search-input {
  height: 64px;
  padding-right: 56px;
  border-color: $gray-100;

  @include border-radius(32px);
  @include box-shadow(0 4px 8px rgba(0, 0, 0, .25));

  @include media-breakpoint-up(md) {
    padding: 1.125rem 9.5rem 1.125rem 1.15625rem;
  }

  &.compact {
    height: 48px;
  }
}

.recommendation-additional-info {
  display: flex;

  i {
    margin-right: 4px;
    font-size: 22px;
    color: $blue-350;
  }

  span {
    display: block;
    padding-bottom: 16px;
    font-size: 14px;
    color: $gray-400;
  }
}


@media (min-width: 1025px) {
  .main-search-input {
    padding: 1.125rem 12rem 1.125rem 1.15625rem;
  }
}

.main-search-btns {
  position: absolute;
  top: 50%;
  right: 4px;
  display: inline-flex;
  transform: translateY(-50%);
  @include media-breakpoint-up(md){
    right: 16px;

  }
  @include media-breakpoint-down(sm){
    //Hide speak button if clear button visible to make more room on mobile
    .clear-btn ~ .speak-btn{
      display: none;
    }
  }
}

.keyboard-search,
.main-search-btns button:not(:last-child) {
  margin-right: 4.5px;
}

.speak-btn,
.clear-btn {
  font-size: 17px;
  @include media-breakpoint-up(md) {
    font-size: 28px;
  }
}

.search-btn {
  margin-left: 8px;
  font-size: 16px;
  @include box-shadow(0 .125rem .625rem rgba(0, 17, 36, .3));
  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}
.btn-icon{
  &.clear-btn{
    background: $white;
  }
}

.clear-btn,
.speak-btn,
.keyboard-btn,
.search-btn {
  width: 40px;
  height: 40px;
  padding: 0;
  @include border-radius(50%);
}

.keyboard-search {
  display: inline-flex;
  align-items: center;
  padding: 0;
  border-radius: 100px;

  &.lang-open {
    background: $gray-300;
  }

}

#keyboard-search-btn {
  border: none;

  &:focus {
    box-shadow: none;
  }

  .fas.fa-keyboard {
    width: 27px;
    height: 28px;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 28px;
  }
}

#keyboard-lang-search {
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding: 8px;

  .dropdown-toggle {
    display: inline-flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $white;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .costom-keyboard-lang-angle-down {
    margin-left: 4px;

    .fa.fa-angle-down {
      width: 13px;
      height: 16px;
      font-family: "Font Awesome 5 Free";
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: 16px;
    }
  }

  .dropdown-menu {
    min-width: auto;
  }

}

// Hide search button by default
#clear-search-btn {
  display: none;
}

// Show search button when search is not showing a placeholder AKA has a value
.main-search:has(#search:not(:placeholder-shown)) {
  #clear-search-btn {
    display: block;
  }
}