.header-container {
  position: sticky;
  top: 0;
  z-index: 2;
  background: $body-bg;

  &.show-header {
    z-index: 999;
  }
  .header-wrap{
    padding: 16px 0;
    @include media-breakpoint-down(sm){
      padding: 8px 0;
    }
  }
}

