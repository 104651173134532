/* stylelint-disable */
.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  min-height: 132px;
  padding: 1.5rem 0;
  font-size: 1rem;
  color: #fff;
  background: #0e1013;

  a {
    color: inherit;
    text-decoration: underline;
  }

  .logo {
    width: 200px;
    height: 50px;
  }

  .row-muted {
    font-size: 14px;
    color: #ccd9e0;
  }

  .fb-icon {
    display: inline-block;
    width: 1rem;
    height: 1.125rem;
    margin-left: .5rem;
    line-height: 1.125rem;
    background: url(../images/fb-icn.svg) center center no-repeat;
    background-size: contain;
  }
}
