.content-wrap {
  padding-top: .625rem;
}

.content-panel {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 100%;

  @include media-breakpoint-up(md) {
    background-color: $white;
    @include box-shadow(0 4px 12px -4px rgba(0, 0, 0, .25));
    @include border-radius(.6875rem);
  }

  .word-details {
    flex: 1 1 auto;

    .sidebar {

      @include media-breakpoint-down(md) {
        border-top: 1px solid $border-color;
      }
    }
  }

  >.loading-indicator {
    display: none;
  }

  &.loading {
    position: relative;

    .loading-indicator {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: $zindex-modal;
      display: block;
      transform: translate(-50%);
    }
  }
}

.lexeme-level {
  line-height: 1.25;
}

.level-2-panel {
  position: relative;
  padding-top: 0;
  margin: 0 -15px;

  &:first-child {
    border-top: none;
  }

  .level-3-panel:last-of-type {
    border-bottom: 0;
  }
}

.level-3-panel {
  position: relative;
  padding: 1rem;
  border-bottom: 1px solid $gray-200;

  @include media-breakpoint-up(md) {
    padding-right: 2.5rem;
  }

  .dependencies {
    .expand .dependence {

      display: inline-flex;
      opacity: 1;
    }

    .dependence:nth-child(1),
    .dependence:nth-child(2) {
      display: inline-flex;
      opacity: 1;
    }
  }

  .dependence.expand {
    display: inline-flex;
    opacity: 1;

    .meaning,
    .meaning-meta {
      flex: 0 1 auto;
    }
  }
}

.level-3-panel:hover .more-btn {
  color: #001124;
}

.more-data .level-3-panel {
  @include media-breakpoint-up(md) {
    padding: 1rem 2.5rem 1rem 1rem;
  }
}

.word-details {
  @include media-breakpoint-down(sm) {
    background: $white;
    @include border-radius(10px);
    @include box-shadow(0 4px 12px -4px rgba(0, 0, 0, .25));
  }
}

.matching-word {
  &.match {
    border-bottom: 2px dashed $border-color;
  }
}

.rections {
  color: $gray-400;
}

.sidebar .level-3-panel:last-child {
  border-bottom: 0;
}

.corp-warning {
  display: flex;
  font-size: $font-size-md;
  color: $gray-400;
}

.word-relations.expand .limited-group,
.word-relations:not(.expand) .full-group {
  display: none;
}

.word-relations.expand .full-group,
.word-relations:not(.expand) .limited-group {
  display: block;
}

.level-3-panel .collocations-section:not(.expand) .colloc-fulldata,
.level-3-panel .collocations-section.expand .colloc-samples {
  display: none;
  opacity: 0;
}

.level-3-panel .collocations-section.expand .colloc-fulldata,
.level-3-panel .collocations-section:not(.expand) .colloc-samples {
  display: block;
  opacity: 1;
}

.main-data {
  @include media-breakpoint-up(md) {
    border-right: 1px $gray-200 solid;
  }
}

.homonym-page {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  min-height: 100%;

  .search-results-header,
  .content-panel {
    width: 100%;
  }

}

.sticky-scroll-panel {
  &__tags {
    display: none;
    flex-wrap: wrap;
    gap: 4px;
  }

  &__tag {
    background-color: var(--color-white);
    border: 1px solid var(--color-bluish-gray);
    border-radius: 100px;
    color: var(--color-gray-900);
    font-weight: 700;
    font-size: .75rem;
    padding: 6px 10px;

    &--active,
    &:active {
      background-color: var(--color-dark-blue);
      color: var(--color-white);

      &:hover {
        color: var(--color-white);
        border-color: var(--color-light-blue);
      }
    }

    &:hover {
      border-color: var(--color-black);
      text-decoration: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  & .sticky-scroll-panel {
    position: sticky;
    background-color: var(--color-gray-010);
    z-index: 2;
    top: 64px;
    margin: 0 -15px;
    padding: 1px 15px 14px;

    &__tags {
      display: flex;
    }
  }
}