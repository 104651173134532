/* stylelint-disable */
//TODO: Split this file in to separate component based partials
@media (max-width: 319px) {
  html::after {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999999;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(255, 255, 255, .5);
  }
  *:not(html) {
    -webkit-filter: blur(.0625rem);
    filter: blur(.0625rem);
  }
}

.break {
  flex-basis: 100%;
  width: 0;
  height: 0;
  overflow: hidden;
}

.dropdown-menu {
  border: none;
  box-shadow: 0 .125rem .625rem rgba(0, 17, 36, .3);
}

.dropdown-menu.container div {
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}


.icon {
  padding-top: 10px;
  font-size: 1.5rem;
}

.fade-target {
  animation: fade 750ms linear;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sidebar .more-btn {
  top: 1.5rem;
}

.label {
  font-size: .75rem;
  color: #7f8891;
}

.label-md {
  color: #7f8891;
}


/* Custome Response */

@media (max-width: 767px) {
  html {
    background-color: #fff;
  }
  .menu-btn {
    top: 0;
    right: 0;
    background-color: transparent;
  }
}

@media (min-width: 768px) {

  .search-wrap {
    top: 4.375rem;
    box-shadow: 0 0 .625rem .3125rem #f2f2f2;
  }
  .dropdown-menu.container {
    width: auto !important;
    max-width: 31.25rem !important;
  }

  .animation-target {
    -webkit-animation: animation 1250ms linear both;
    animation: animation 1250ms linear both;
  }
  @-webkit-keyframes animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    70% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    75% {
      -webkit-transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    80% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    85% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    90% {
      -webkit-transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
  }
  @keyframes animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    70% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    75% {
      -webkit-transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    80% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    85% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    90% {
      -webkit-transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
  }


}


@media (min-width: 1200px) {

  .header-wrap,
  .search-panel,
  .footer-wrapper,
  .content-panel,
  .search-results-header {
    max-width: 80vw !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}


.alert-icn {
  position: relative;
  top: .1rem;
  right: 0;
  left: .25rem;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url(../images/alert-icn.svg) center center no-repeat;
  background-size: 1rem;
}


.word-grouper-wrapper{
  .word-options a {
    position: relative;
    line-height: 1.3;
  }
  .word-options sup,
  .collapse-panel:not(.expanded) .word-options.limit sup{
    position: absolute;
    top: 3px;
    right: -6px;
    left: auto;
  }
  .word-options:not(:only-child):not(:last-child) sup {
    right: 12px;
  }

  .word-options:not(:last-child) a > span::after {
    display: inline;
    margin-right: .5rem;
    color: $body-color;
    text-decoration: none;
    content: ",";
  }
  .word-options:not(:last-child) a.is-homonym > span::after{
    margin-left: 5px;
  }

.collapse-panel:not(.expanded) {
    .word-options.limit {

      &.limit-collapsed {

        a>span::after {
          display: none;
        }
      }

      &:not(.limit-collapsed) {

        a>sup {
          right: 12px;
        }
      }
    }
  }

}

.dependence-group {
  @include media-breakpoint-up(md) {
    margin-right: 2.5rem;
  }

}

.show-section {
  display: block !important;
}

.formarea {
  display: none;
}

.heading-wrapper {
  display: inline-flex;
}

.games-wrap {
  margin-top: 4.375rem;
}

.games-list .card-body {
  display: -webkit-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.games-list .card-body div {
  width: 100%;
  padding-bottom: .25rem;
  margin-bottom: .25rem;
  border-bottom: 1px solid rgba(0, 17, 36, .125);
}

.games-list .card-body div:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0 solid red;
}


.dotted-list {
  padding-left: 1.5rem;
}

.dotted-list li {
  position: relative;
}

.dotted-list li::before {
  position: absolute;
  top: .5rem;
  left: -1rem;
  display: inline-block;
  width: .5rem;
  height: .5rem;
  content: "";
  background-color: rgb(0, 157, 181);
  border-radius: 1rem;
}

.alert-danger-ie {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  color: #856404;
  background: #fff3cd;
  border: 1px solid #ffeeba;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}

.alert-danger-ie-container {
  max-width: 1400px;
}

@media (max-width: 767px) {
  .main-content-wrapper.align-self-center {
    align-self: auto !important;
    margin-top: 3.125rem;
  }


  .detail-toggle-container {
    margin: 0 auto;
  }

  .search-lang .dropdown-menu {
    right: 1rem !important;
    left: 1rem !important;
    width: unset;
    min-width: inherit;
    -webkit-transform: translate3d(0, 43px, 0) !important;
    transform: translate3d(0, 43px, 0) !important;
  }
  .more-btn .icon::before {
    top: .85rem;
  }

  .morphology-panel,
  .relations-wrapper,
  .sentence-wrapper {
    padding-right: 2rem;
  }
}

@media (max-width: 1200px) {
  .lg-max-noneset {
    margin-left: 0 !important;
  }
}
