.collapse-text {
  overflow: hidden;
}

.get-height{
  position: absolute;
  visibility: hidden;
}

.good-to-know-info {
  div:not(:first-of-type) {
    margin-top: .5rem;
  }
}
