/* stylelint-disable */
@import "variables";

.modal {
  .modal-dialog {
    max-width: 800px;
  }

  .modal-header {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 80px;
    padding: 1.5rem;

    .modal-title {
      font-size: 1.25rem;
    }

    .close {
      padding: 0;
      margin: 0;
    }
    .close .fa {
      padding: 0;
      font-size: 32px;
      font-weight: 900;
      line-height: 37px;
    }
  }

  .modal-body {
    padding: 1.5rem;

    small {
      font-size: .875rem;
      line-height: 1rem;
      color: #5d606e;
    }


    .btn {
      padding: 10px 32px;
      line-height: 20px;
      border-radius: 20px;
    }
  }
}

.wordmodal {

  .subtitle {
    padding-top: 20px;
    padding-bottom: 0;
  }
  tr:nth-child(2) > .subtitle {
    padding-top: 0;
    padding-bottom: 0;
  }

  .text-center {
    font-size: 18px;
    font-weight: bold;
  }
  .uppercase {
    text-transform: uppercase;
    margin-bottom: -10px;
    & + h2 {
      margin-top: 20px;
    }
  }
  .highlight {
    color: $green-400;
  }
  .modal-dialog {
    max-width: 900px;
  }
  .modal-content {
    border-radius: 10px;
  }
  .modal-header {
    height: 60px;
    .close {
      font-size: 32px;
    }
  }
  .scrollable-table {
    position: relative;
    overflow-x: hidden;
    margin-left: -10px;
    margin-right: -10px;
    &--scroller {
      overflow: auto;
    }
    &--indicatorRight {
      position: absolute;
      top: 12px;
      right: 0;
      bottom: 12px;
      z-index: 11;
      display: none;
      width: 40px;
      pointer-events: none;
      background: linear-gradient(to right, rgba(137, 150, 164, 0) 0%, rgba(137, 150, 164, .71) 100%);
    }
    &--indicatorLeft {
      position: absolute;
      top: 12px;
      bottom: 12px;
      left: 0;
      z-index: 11;
      display: none;
      width: 40px;
      pointer-events: none;
      background: linear-gradient(to right, rgba(137, 150, 164, .71) 0%, rgba(137, 150, 164, 0) 100%);
    }
  }
  .modal-title {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: $gray-1000;
    span {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 400;
      color: $gray-900;
    }

  }
  .modal-body{
    padding: 16px 25px;
    color: $gray-900;

    .nav-pills {
      border-bottom: #CCD9E0 1px solid;
      margin-left:-25px !important;
      margin-right: -25px !important;
      padding: 0 25px 16px 25px;
      .nav-link {
        border: none;
        padding: 0;
        margin: 0;
        background: none;
        color: $gray-900 !important;
        .fa {
          width: 20px;
          height: 20px;
          display: inline-block;
          line-height: 18px;
          font-size: 12px;
          text-align: center;
          border-radius: 4px;
          color: #fff;
          margin-right:12px;
          border: $blue-400 1px solid;
          &:before {
            visibility: hidden;
          }
        }
        &.active {
          .fa {
            background: $blue-400;
            &:before {
              visibility: visible;
            }
          }
        }
      }
    }
    .btn {
      padding: 5px 12px;
      font-size: 16px;
      border-radius: 5px;
    }
    .nav-pills{
      .nav-item + .nav-item {
        .nav-link {
          border-radius: 0;
        }
      }
      .nav-item {
        &:last-child {
          .nav-link {
            border-radius: 0 5px 5px 0;
          }
          &:first-child {
            .nav-link {
              border-radius: 5px;
            }
          }
        }
      }
      margin: 13px 0;
      &:first-child {
        margin-top: 0;
      }
    }
    .btn-speaker {
      padding: 0;
      margin: -7px 0;
      .icon-speaker {
        font-size: 21px;
      }
    }
  }

  h2 {
    margin: 26px 0 12px;
    font-size: 18px;
    font-weight: normal;
    color: $gray-900;
    text-transform:  uppercase;
    & + .scrollable-table {
      margin-top: -20px;
    }
  }
  p {
    margin: .5rem 0;
  }
  p + h2 {
    margin-top: 0;
  }

  .paradigm-text {
    margin: 0 0 20px;
    line-height: 19px;
  }

  .tableClone {
    position: absolute;
    top: 12px;
    left: 0;
    bottom: 12px;
    z-index: 10;
    width: 200px;
    overflow: hidden;
    pointer-events: none;
    background: $white;
    table {
      margin: 0;
    }
    td,th {
      &:nth-child(2){
        visibility: hidden;
      }
    }
  }
  .word-sufix {
    min-width: 35px;
    display: inline-block;
  }
  .word-sufix-rus {
    min-width: 80px;
    display: inline-block;
  }
  .word-prefix-adjective-rus {
    min-width: 125px;
    display: inline-block;
    color: $gray-350;
    font-size: 12px;
  }

  table {
    min-width: 100%;
    margin: 12px 0;
    line-height: 16px;
    color: $gray-900;
    white-space: nowrap;
    table-layout: fixed;
    &.first-row-sizes {
      td:nth-child(1) {
        font-size: 16px;
        .highlight {
          font-size: 12px;
        }
      }
    }
    &.inflected {
      td:nth-child(1) {
        .highlight {
          font-size: 16px;
        }
      }
    }
    table {
      margin: 0;
      td {
        padding: 0;
        border: none;
        width: 50%;
      }
    }
    &.stretch-middle-td {
      table-layout: unset;
      td:nth-child(1) {
        width: 25%;
        font-size: 16px;
        .highlight {
          font-size: 14px;
        }
      }
    }
    th {
      font-size: 16px;
      font-weight: 700;
      line-height: 120%;
      color: $gray-900;
      span {
        display: block;
      }
      &[colspan="3"] {
        border: none;
        font-size: 18px;
        line-height: 22px;
        font-weight: normal;;
        &.text-center {
          font-weight: bold;
        }
      }
    }
    th,
    td {
      padding: 10px 12px;
      border-bottom: 1px solid $gray-100;
    }
    td {
      padding-top: 5px;
      padding-bottom: 5px;
      color: $gray-900;
      &:first-child {
        .highlight {
          color: $gray-350;
          font-size: 12px;
        }
      }
    }
  }

  .word-section {
    display: inline-block;
    vertical-align: middle;
  }
  .rating {
    display: inline-block;
    margin: 0 0 3px 8px;
    font-size: 8px;
    line-height: 120%;
    white-space: nowrap;
    vertical-align: middle;
    &__row {
      color: $gray-200;
      &--highlighted {
        color: $green-400;
      }
    }
  }

  .table-legend {
    font-size: 14px;
    .fa {
      margin-right: 4px;
      color: $gray-200;
      &.highlight {
        color: $green-400;
      }
    }
    &__item {
      display: inline-block;
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .legend-sign {
    font-size: 14px;
    font-weight: bold;
    min-width: 32px;
    text-align: center;
  }
  .legend-text {
    font-size: 14px;
    white-space: normal;
  }
  
  
  @media (max-width: 767px) {
    .modal-title span {
      display: block;
      margin: 0;
    }
    .modal-dialog {
      margin: 0;
      .modal-content {
        border: none;
        border-radius: 0;
      }
    }
    .modal-body,
    .modal-header {
      padding-right: 8px;
      padding-left: 8px;
    }
    .table-legend {
      &__item {
        display: block;
      }
    }
  }
}


.feedback-modal {
  border-radius: 16px;
  box-shadow: none;
  border: none;
  overflow: hidden;
  color: var(--color-dark-blue);

  &__header {
    border: none;
    padding: 28px;
  }
  &__close {
    background: transparent;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 24px;
    height: 24px;
    font-size: 1.5rem;

    &:hover {
      border-color: black;
    }
  }

  &__required-text {
    color: var(--color-orange);
  }

  &__want-response {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 28px;
    background-color: var(--color-light-blue);
    margin: 0 -28px -24px;

    --feedback-modal-group-margin-bottom: 0;

    & h2,
    & p {
      margin: 0;
    }

    & h2 {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 29px;
    }

    & p {
      font-size: .875rem;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.0025em;
    }

    & a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &__button {
    height: 48px;
    padding: 4px 24px;
    border-radius: 100px;
    background-color: var(--color-dark-blue);
    color: var(--color-white);
    width: max-content;
    margin-left: auto;
    border: 0;
    &:hover {
      background-color: var(--color-dark-blue-75);
    }

    &:focus {
      outline: 1px solid black;
      outline-offset: 1px;
    }
  }

  &__group {
    margin-bottom: var(--feedback-modal-group-margin-bottom, 16px);

    &--last {
      margin-bottom: 28px;
    }
  }

  & .modal-body {
    padding: 4px 28px 24px;
  }

  
  & .form-control {
    padding: 10px 16px;
    border-radius: 8px;
    border-color: var(--color-dark-blue-75);
    height: 40px;
  }

  & textarea.form-control {
    min-height: 100px;
  }

  & .col-form-label {
    padding: 0 0 6px 0;
    font-size: .75rem;
    line-height: 15px;
    letter-spacing: 0.004em;
  }

  & .modal-title {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 29px;
  }

  & input::placeholder {
    color: var(--color-dark-blue-75);
  }
}