.lang-code {
  display: inline-block;
  flex: 0 0 auto;
  min-width: 24px;
  height: 20px;
  padding: 2px 5px 3px;
  overflow: visible;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  line-height: 1.4;
  color: $gray-400;
  text-align: center;
  cursor: default;
  background: $gray-100;
  @include border-radius(4px);
}
