.content-title {
  z-index: 1;
  padding: 9px 16px;
  border-bottom: 1px solid $gray-200;
}
.content-title-date {
  font-size: 14px;
  font-weight: 400;
  color: $gray-400;
  i {
    margin-right: 5px;
  }
}

