.news-page {
  background-color: var(--color-white);

  &__wrapper {
    display: grid;
    grid-template-columns: [full-width-start] 1fr [content] minmax(375px, 750px) [full-width-end] 1fr;
    gap: 16px;
    flex-grow: 1;
    margin-bottom: 36px;

    background-image: url("../images/bg-arrow.svg"), url("../images/bg-colon.svg"), url("../images/bg-tilde.svg"), url("../images/bg-quotes.svg");
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    // Alternate left and right positions via percentages
    background-position: calc(100% - 87px) 268px, 87px calc(268px + 25%), calc(100% - 87px) calc(268px + 50%), 87px calc(268px + 75%), calc(100% - 87px) calc(268px + 100%);

    @media (max-width: 1200px) {
      background-image: none;
    }
  }

  &__header {
    grid-column: 1/-1;
    padding: 36px 0;
    background-color: var(--color-light-blue);
    display: grid;
    grid-template-columns: subgrid;
    align-items: center;
    text-align: center;
    gap: 16px;
    color: var(--color-dark-blue);
    height: 220px;

    background-image: url("../images/bg-quotes.svg");
    background-repeat: no-repeat;
    background-position: 87px 48px;

    & h1,
    & p {
      margin: 0;
      grid-column: content;
    }

    & h1 {
      font-size: 2.125rem;
      font-weight: 400;
      line-height: 41.15px;
      letter-spacing: 0.0025em;
    }

    & p {
      font-size: .875rem;
      font-weight: 500;
      line-height: 19.6px;
      letter-spacing: 0.0025em;
    }

    @media (max-width: 1200px) {
      background-image: none;
    }
  }

  &__content {
    grid-column: content;
  }

  &__item {
    padding: 36px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid var(--color-light-gray);

    & h2,
    & p {
      margin: 0;
      color: var(--color-dark-blue);
    }

    & h2,
    & small {
      font-weight: 400;
    }

    & h2 {
      font-size: 1rem;
      line-height: 19.36px;
      letter-spacing: 0.0015em;
      margin-bottom: 8px;
      font-weight: 700;
    }

    & p {
      font-size: .875rem;
      font-weight: 500;
      line-height: 19.6px;
      letter-spacing: 0.0025em;
      white-space: pre-line;
    }

    & small {
      font-size: .75rem;
      line-height: 14.52px;
      letter-spacing: 0.004em;
      color: var(--color-dark-blue-75);
      margin-top: 8px;
    }
  }
}