/* stylelint-disable */
:root {
  --wordgame-gray: #CCD9E0;
  --wordgame-card-top-padding: 8px;
  --wordgame-card-top-button-size: 1;
  --wordgame-card-description-padding: 20px;
  --wordgame-card-bottom-padding: 8px;
  --wordgame-card-bottom-text-padding: 8px;
  --wordgame-static-picture-height: 340px;
  --wordgame-menu-margin-left: 24px;
  --wordgame-menu-min-width: 250px;
  --wordgame-menu-category-spacing: 9px;
  --wordgame-options-bottom-margin: 18px;
  --wordgame-grid-margin: 8px;
  --wordgame-search-sides-padding: 24px;
}

body.has-wordgame {
  .header-container {
    z-index: 4;
  }
}

.wordgame {
  &__main {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 0;
    margin-bottom: 12px;
    border-top: 1px solid #CCD9E0;
    
    &.content-panel {
      box-shadow: none;
    }

    @media (min-width: 1500px) {
      max-width: 1200px !important;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__top-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 20px;
    gap: 8px;
    background-color: #ffffff;
    
    @media (min-width: 1200px) {
      max-width: 80vw !important;
      margin-right: auto !important;
      margin-left: auto !important;
    }

    @media (min-width: 1500px) {
      max-width: 1200px !important;
    }
  }

  &__top-heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    h1 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 1.75rem;
      color: #0E1013;
    }
  }

  &__options-wrapper {
    display: grid;
    max-width: max-content;
    gap: 8px;
    grid-template-columns: 1fr;
  }

  &__options {
    width: 100%;
    background: none;
    box-shadow: none;

    
    .form-check-label {
      cursor: pointer;
    }
    .form-check {
      display: flex;
      align-items: center;
      .wordgame-customRadio {
        margin-right: 10px !important;
      }
    }
    
    ul {
      display: flex;
      flex-wrap: wrap;
      
      li:nth-of-type(3) {
        span {
          margin-right: 10px;
        }

        span:nth-of-type(3) {
          margin-right: 0;
          margin-left: 5px;
        }
        
        margin-right: 0;
      }
      
      li {
        .form-check {
          padding-left: 0.5rem;
        }
      }
    }
    
    ul,
    li {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    li {
      display: flex;
      margin: 4px 30px;
      margin-left: 0;
    }
    
    @media (max-width: 768px) {
      ul {
        li:nth-of-type(2) {
          display: none;
        }

        li:nth-of-type(3) {
          display: none;
        }

        flex-wrap: nowrap;
      } 
    }

    @media (max-width: 340px) {
      ul {
        li {
          .form-check {
            padding-left: 0.4rem;

            .wordgame-customRadio {
              margin-right: 5px !important;
            }
          }
        }
      }
    }

    @media (any-hover: none), (pointer: coarse) {
      ul {
        li:nth-of-type(3) {
          display: none;
        }
      }
    }
  }

  &__options-bottom {
    display: flex;
    gap: 16px;

    .wordgame-language-select {
      height: 48px;
      padding: 12px 36px 12px 16px;
      border: 1px solid #8996A4;
      border-radius: 100px;
      line-height: 19px;

      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      background-color: #fff;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEzIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjQxNzI4IDBDMC43MTQxNTYgMCAwLjM2MjU5NCAwLjg1OTM3NSAwLjg3MDQwNiAxLjM2NzE5TDUuODcwNDEgNi4zNjcxOUM2LjE4MjkxIDYuNjc5NjkgNi42OTA3MiA2LjY3OTY5IDcuMDAzMjIgNi4zNjcxOUwxMi4wMDMyIDEuMzY3MTlDMTIuNTExIDAuODU5Mzc1IDEyLjE1OTUgMCAxMS40NTYzIDBIMS40MTcyOFoiIGZpbGw9IiM4OTk2QTQiLz4KPC9zdmc+Cg==");
      background-repeat: no-repeat;
      background-position-x: calc(100% - 16px);
      background-position-y: 50%;

      &:focus,
      &:focus-visible {
        border-color: #81afe1;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(44, 111, 182, 0.25);
      }
    }
  }

  &__top-mobile-menu {
    width: 100%;
    display: none;
    border-top: 1px solid #CCD9E0;
  }

  &__content {
    width: 100%;
    min-height: 370px;

    h2 {
      font-weight: 700;
    }

    .wordgame__category--header,
    .wordgame__subcategory--header {

      p {
        margin: 16px;
      }

      h2,
      h3 {
        margin-right: 16px;
        margin-left: 16px;
      }
    }

    .wordgame__category--header {
      h2 {
        margin-top: 16px;
        margin-bottom: 16px;
        font-size: 1.75em;
        color: #000;

        @media (max-width: 768px) {
          font-size: 1.4em;
        }
      }
    }

    .wordgame__subcategory--header {
      border-top: 1px var(--wordgame-gray) solid;

      h3 {
        margin-top: 16px;
        font-size: 1.25em;
        font-weight: 700;
        color: #5D606E;
      }
    }
  }

  &__menu {
    min-width: var(--wordgame-menu-min-width);
    flex-shrink: 0;

    border-right: 1px var(--wordgame-gray) solid;

    height: calc(100vh - 79px);
    overflow-y: scroll;
    position: sticky;
    top: 79px;

    ul,
    li {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    ul {
      margin-top: 8px;
    }

    li {
      display: block;
      margin-right: 30px;
      margin-left: var(--wordgame-menu-margin-left);
      font-size: 1.25rem;
    }

    button {
      padding: 0;
      margin: var(--wordgame-menu-category-spacing) 0;
      color: var(--primary);
      background: none;
      border: none;
      appearance: none;

      &:hover {
        color: #1d4978;
        
        img {
          filter: brightness(80%);
        }
      }

      &:focus {
        outline: 2px solid black;
      }
    }

    img {
      display: inline-block;
      width: 1.625rem;
      margin-right: 0.3125rem;
    }
  }

  &__col {
    padding: var(--wordgame-grid-margin);
  }
}

.wordgame__card {
  font-size: 0.875rem;

  p {
    font-size: 1em;
    padding: 0;
    margin: 0;
    margin-top: var(--wordgame-card-description-padding);
  }

  h3 {
    font-size: 18px;
    margin-top: 2px;
    margin-bottom: 10px;
    color: #687887;
    font-weight: 700;
  }
  
  img {
    width: 16px;
  }

  &--bottom {
    flex-shrink: 0;
    flex-grow: 0;
    color: black;
    padding: var(--wordgame-card-bottom-padding);
    font-size: 1em;
    border-top: 1px var(--wordgame-gray) solid;
  }

  @media (max-width: 576px) {
    font-size: 14px;
  }

  @media (min-width: 576px) {
    font-size: 16px;
  }

  @media (min-width: 768px) {
    font-size: 14px;
  }

  &__icon {
    height: 20px;
    margin: 5px;
  }

  &__thumb {
    position: relative;
    padding-bottom: 120%;
    background: center center no-repeat;
    background-size: contain;
    border-radius: 3px;

    img {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &.wordgame-no-image {
      background-color: #ECF0F2;
    }
  }

  &--top {
    position: relative;
    flex-grow: 1;
    min-width: 100px;
    overflow: hidden;
    font-size: 1em;
    padding: var(--wordgame-card-top-padding);

    .wordgame-no-image-text {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        margin: 0;
        z-index: 1;
        text-align: center;
        color: #8996A4;
        user-select: none;
        line-height: 24px;
        font-size: 1rem;
      }
    }
  }

  &__audiobtn {
    border: none;
    appearance: none;
    background: none;
  }

  &__name {
    min-height: 32px;
    background-color: var(--light);
    display: block;
    font-weight: bold;
    width: 100%;
    border-radius: 40px;
    border: none;
    appearance: none;
    color: black;

    img {
      display: none;
    }

    @media (any-hover: none), (pointer: coarse) {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      img {
        display: inline;
        width: 16px;
        height: auto;
      }
    }

    &:hover {
      color: #fff;
      cursor: url("../images/speaker-icon-light.png"), auto;
      background-color: var(--primary);

      img {
        filter: brightness(0) invert(1);
      }

    }

    &:focus {
      outline: 2px solid black;
    }

    &.wordgame-no-sound,
    &.wordgame-sound-disabled {
      cursor: default;
    }

    &.wordgame-sound-disabled {
      pointer-events: none;

      &:hover {
        background-color: var(--light);
        color: black;
      }
    }
  }
}

.card--type-2 {
  position: relative;
  backface-visibility: hidden;
  transition: transform 0.25s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -webkit-transition: -webkit-transform 0.25s;
  -o-transform-style: preserve-3d;
  -o-transition: -o-transform 0.25s;
  -moz-transform-style: preserve-3d;
  -moz-transition: -moz-transform 0.25s;

  border-radius: 4px;

  .card__face {
    position: relative;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    border: 1px var(--wordgame-gray) solid;
    border-radius: 4px;
    transition: transform 0.25s;
    -webkit-transition: -webkit-transform 0.25s;
    -o-transition: -o-transform 0.25s;
    -moz-transition: -moz-transform 0.25s;
    transform: rotateX(0deg);
    transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    background-color: white;
  }

  .card__back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    border: 1px var(--primary) solid;
    border-radius: 4px;
    display: flex;
    transform: rotateX(0deg);
    transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    flex-direction: column;
    transition: transform 0.25s;
    -webkit-transition: -webkit-transform 0.25s;
    -o-transition: -o-transform 0.25s;
    -moz-transition: -moz-transform 0.25s;
    background-color: white;

    .wordgame__card--top {
      overflow-y: auto;
    }
  }

  .card__button {
    position: absolute;
    top: var(--wordgame-card-top-padding);
    z-index: 2;
    padding: 0;
    background-color: #fff;
    border: 1px solid var(--wordgame-gray);
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    align-items: center;
    font-size: 1rem;
    justify-content: center;
    appearance: none;

    &:focus {
      outline: 2px solid black;
    }

    img {
      display: block;
      width: 20px;
      height: 20px;
      margin: 0;
    }

    &--left {
      left: var(--wordgame-card-top-padding);
    }

    &--right {
      right: var(--wordgame-card-top-padding);
    }

  }

  &.card--flipped .card__face {
    transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
  }

  &.card--flipped .card__back {
    transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
  }
}

.wordgame-static-picture {
  display: flex;
  padding: 8px;
  position: relative;
  border: 1px var(--wordgame-gray) solid;
  justify-content: center;
  border-radius: 4px;

  min-height: calc(18px + var(--wordgame-static-picture-height));
  width: 80%;
  margin: 0 auto;

  @media (max-width: 992px) {
    width: 100%;
  }

  img {
    width: 100%;
    max-height: var(--wordgame-static-picture-height);
  }
}

.wordgame-multicard {
  display: flex;
  position: relative;
  border: 1px var(--wordgame-gray) solid;
  justify-content: center;
  border-radius: 4px;

  width: 50%;
  margin: 0 auto;
  
  &.bigger {
    width: 70%;
  }

  @media (max-width: 992px) {
    width: 100%;

    &.bigger {
      width: 100%;
    }
  }

  img {
    width: 100%;
  }

  &-label:hover {
    cursor: url("../images/speaker-icon-light.png"), auto;

    &.wordgame-no-sound {
      cursor: default;
    }
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.wordgame__row {
  @media (min-width: 768px) {
    margin: var(--wordgame-grid-margin) !important;
  }

  @media (max-width: 768px) {
    margin: 0 !important;
  }
}

.wordgame-active {
  font-weight: 700;
}

.wordgame-card-hidden {
  visibility: hidden;

  .wordgame__card--top {
    overflow-y: hidden;
  }
}

.wordgame-text-upper {
  text-transform: uppercase;

  button {
    text-transform: uppercase;
  }
}

.wordgame-customRadio {
  display: inline-block;
  position: relative;
  font-size: 1rem;
  width: 1.25em;
  height: 1.25em;
  border-radius: 0.625em;
  background: white;
  margin: 0 !important;
  
  input:checked .wordgame-customRadio {
    border: none;
  }
  * {
    margin: 0 !important;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
    &:checked + .wordgame-customRadio__indicator:before {
      display: block;
    }
    &:focus + .wordgame-customRadio__indicator {
      outline: 2px solid black;
    }
  }
  &__indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.625em;
    border: 0.0625em solid #8996A4;
    &:before {
      display: block;
      content: " ";
      position: absolute;
      top: -0.0625em;
      left: -0.0625em;
      right: -0.0625em;
      bottom: -0.0625em;
      background: var(--primary);
      border-radius: 0.625em;
      display: none;
    }
    
    &:after {
      display: block;
      content: " ";
      position: absolute;
      top: 0.3125em;
      left: 0.3125em;
      right: 0.3125em;
      bottom: 0.3125em;
      background: #fff;
      border-radius: 0.625em;
    }
  }
}

.wordgame-custom-toggle {
  .custom-control-label::before{
    width: 2rem;
    border-radius: 0.6rem;
    top: 0.2rem;
    height: 1.2rem;
  }

  &:focus-within .custom-control-label::before{
    box-shadow: none;
    outline: 2px solid black;
  }

  .custom-control-label::after{
    top: calc(0.20rem + 2px);
    left: calc(-2.20rem + 2px);
    width: calc(1.2rem - 4px);
    height: calc(1.2rem - 4px);
    border-radius: 0.6rem;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #007025;
    background-color: #007025;
  }
}

.wordgame-top-category {
  display: none;
}

.wordgame__error {
  display: flex;
  gap: 1rem;
  padding: 1rem;

  background-color: #FFC107;
  color: #0E1013;

  p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 24px;
  }

  .wordgame-triangle-exclamation {
    width: 24px;
    height: 23px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyNCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIzLjIyNjYgMTcuNzI2NkwxMy44NTE2IDEuNDM3NUMxMy4xNDg0IDAuMjI2NTYyIDExLjMxMjUgMC4xODc1IDEwLjYwOTQgMS40Mzc1TDEuMjM0MzggMTcuNzI2NkMwLjUzMTI1IDE4LjkzNzUgMS40Mjk2OSAyMC41IDIuODc1IDIwLjVIMjEuNTg1OUMyMy4wMzEyIDIwLjUgMjMuOTI5NyAxOC45NzY2IDIzLjIyNjYgMTcuNzI2NlpNMTIuMjUgMTQuMzI4MUMxMy4yMjY2IDE0LjMyODEgMTQuMDQ2OSAxNS4xNDg0IDE0LjA0NjkgMTYuMTI1QzE0LjA0NjkgMTcuMTQwNiAxMy4yMjY2IDE3LjkyMTkgMTIuMjUgMTcuOTIxOUMxMS4yMzQ0IDE3LjkyMTkgMTAuNDUzMSAxNy4xNDA2IDEwLjQ1MzEgMTYuMTI1QzEwLjQ1MzEgMTUuMTQ4NCAxMS4yMzQ0IDE0LjMyODEgMTIuMjUgMTQuMzI4MVpNMTAuNTMxMiA3Ljg4MjgxQzEwLjQ5MjIgNy42MDkzOCAxMC43MjY2IDcuMzc1IDExIDcuMzc1SDEzLjQ2MDlDMTMuNzM0NCA3LjM3NSAxMy45Njg4IDcuNjA5MzggMTMuOTI5NyA3Ljg4MjgxTDEzLjY1NjIgMTMuMTk1M0MxMy42MTcyIDEzLjQ2ODggMTMuNDIxOSAxMy42MjUgMTMuMTg3NSAxMy42MjVIMTEuMjczNEMxMS4wMzkxIDEzLjYyNSAxMC44NDM4IDEzLjQ2ODggMTAuODA0NyAxMy4xOTUzTDEwLjUzMTIgNy44ODI4MVoiIGZpbGw9IiMwRTEwMTMiLz4KPC9zdmc+Cg==);
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
  }
}

.wordgame__options-collapse {
  // display: flex;
  display: none;
  flex-direction: column;
  gap: 16px;

  &-btn {
    background: none;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 0.875rem;
    justify-content: flex-start;
    font-weight: 700;
    color: #687887;

    .collapse-show-less {
      display: none;
    }
  }

  .wordgame-collapse-content {
    display: none;
  }

  .collapse-option-radio {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &--left,
    &--right {
      position: relative;
      height: 44px;
      border: 1px solid #00892E;
      overflow: hidden;

      input {
        opacity: 0;

        &:checked + label {
          background-color: #007025;
          color: #fff;
        }
      }

      label {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: grid;
        place-content: center;
        margin: 0;

        color: #00892E;
      }
    }

    &--left {
      border-radius: 100px 0px 0px 100px;
      
      label {
        padding-left: 8px;
      }
    }

    &--right {
      border-radius: 0px 100px 100px 0px;
      
      label {
        padding-right: 8px;
      }
    }
  }

  &.collapse-open {
    .wordgame-collapse-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .wordgame__options-collapse-btn {
      .collapse-show-more {
        display: none;
      }

      .collapse-show-less {
        display: initial;
      }
      
      .fa {
        transform: rotate(180deg);
      }
    }
  }
}

.wordgame-search {
  position: relative;
  height: 48px;
  flex-grow: 1;
  background: none;

  &:focus-within {
    .wordgame-search__input,
    .wordgame-search__autocomplete {
      border-color: #81afe1;
      outline: 0;
    }

    .wordgame-search__input {
      box-shadow: 0 0 0 0.2rem rgba(44, 111, 182, 0.25);
    }
  }

  &.open:focus-within {
    .wordgame-search__input {
      clip-path: inset(-8px -8px 0px -8px);
      box-shadow: 0 0 0 0.2rem rgba(44, 111, 182, 0.25);
    }
    
    .wordgame-search__autocomplete {
      clip-path: inset(0px -8px -8px -8px);
      box-shadow: 0 0 0 0.2rem rgba(44, 111, 182, 0.25);
    }
  }

  &__input,
  &__autocomplete {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &__bar {
    position: relative;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 19px;

    color: #3F3F3F;
    background: none;
  }

  &__buttons {
    position: absolute;
    right: var(--wordgame-search-sides-padding);
    display: flex;
    gap: 16px;

    .wordgame-search__button {
      &-close,
      &-voice,
      &-search {
        height: 28px;
        width: 28px;

        background: transparent;
        border: 1px solid transparent;
        padding: 0;
        border-radius: 50%;

        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        font-size: 20px;

        &:focus {
          outline: 0;
        }
      }

      &-close,
      &-voice {
        color: #8996A4;

        &:hover,
        &:focus {
          color: #3f3f3f;
          background-color: #f0f0f0;
          border-color: #f0f0f0;
        }
        
        &:focus {
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
        }
      }

      &-close {
        
      }

      &-voice-stop {
        display: inline-block;
        font-weight: 400;
        color: #8a98a5;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 6px 12px;
        font-size: 16px;
        line-height: 1;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover {
          color: #3f3f3f;
          background-color: #f0f0f0;
          border-color: #f0f0f0;
        }
      }

      &-search {
        color: #ffffff;
        background: #00892E;
        font-size: 14px;

        &:hover,
        &:focus {
          color: #fff;
          background-color: #006321;
          border-color: #00561d;
        }

        &:focus {
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 0 0.2rem rgba(38, 155, 77, 0.5);
        }
      }
    }
  }
  
  &__input {
    width: 100%;
    height: 100%;
    
    padding: 10px calc(var(--wordgame-search-sides-padding) + 120px) 10px var(--wordgame-search-sides-padding);
    border-radius: 24px;
    background-color: white;
    color: #3F3F3F;

    border: 1px solid #8996A4;
    outline: 0;
  }

  &__autocomplete {
    position: absolute;
    top: 47px;
    width: 100%;
    z-index: 3;

    padding-bottom: 8px;

    background-color: white;
    border-radius: 0 0 24px 24px;
    
    border: 1px solid #8996A4;
    border-top: 0;

    // display: flex;
    display: none;
    flex-direction: column;
    gap: 6px;

    overflow: hidden;
  }

  &__divider {
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;    
    text-transform: uppercase;
    color: #8996A4;

    padding: 0 var(--wordgame-search-sides-padding);

    display: flex;
    align-items: center;
    gap: 8px;

    &-line {
      height: 0;
      width: 100%;
      display: block;
      border-top: 1px solid #8996A4;
    }
  }

  &__minus,
  &__word,
  &__category {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 19px;
    text-align: left;
    color: #0E1013;
  }

  &__category {
    font-style: italic;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    flex-direction: column;

    &-result {
      width: 100%;
      padding: 6px var(--wordgame-search-sides-padding);

      display: flex;
      gap: 8px;
      
      border: none;
      background: none;
      cursor: pointer;

      span {
        pointer-events: none;
      }
  
      &:hover,
      &:focus {
        background-color: #007FFF;
        color: #ffffff;
        outline: 0;
  
        span {
          color: #ffffff;
        }
      }
    }
  }

  &__active-item {
    background-color: #007FFF;
    color: #ffffff;
    outline: 0;

    span {
      color: #ffffff;
    }
  }

  &__noresults {
    padding: 4px var(--wordgame-search-sides-padding);

    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 19px;
    color: #0E1013;
  }

  &.open {
    .wordgame-search__input {
      border-radius: 24px 24px 0 0;
      border-bottom: 0;
    }

    .wordgame-search__autocomplete {
      display: flex;
    }
  }
}

.testanimation {
  animation: testhighlight 8s forwards ease-out;
}

@keyframes testhighlight {
  0% {
    box-shadow: 0px 0px 0px 0px rgb(0, 137, 46);
  }

  10% {
    box-shadow: 0px 0px 16px 8px rgb(0, 137, 46);
  }

  50% {
    box-shadow: 0px 0px 16px 8px rgb(0, 137, 46);
  }

  90% {
    box-shadow: 0px 0px 16px 8px rgb(0, 137, 46);
  }

  100% {
    box-shadow: 0px 0px 0px 0px rgb(0, 137, 46);
  }
}

.wordgame-hidden {
  display: none;
}

.wordgame-hidden-block {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 768px) {
  .wordgame__top-mobile-menu {
    display: block;
    position: sticky;
    top: 64px;
    z-index: 2;
    border-bottom: 1px solid #CCD9E0;
  }

  .wordgame__main {
    border-top: none;
  }

  .wordgame__category--header,
  .wordgame__top-heading,
  .wordgame__options {
    display: none;
  }
  
  .wordgame__options-bottom {
    flex-direction: column;
  }

  .wordgame-search .wordgame-search__bar {
    .wordgame-search__input {
      padding: 10px calc(var(--wordgame-search-sides-padding) + 74px) 10px var(--wordgame-search-sides-padding);
    }

    .wordgame-search__button-voice {
      display: none;
    }
  }

  .wordgame__content > .subcategory-container {
    .wordgame__subcategory--header {
      border: none;
    }

    ~ .subcategory-container {
      .wordgame__subcategory--header {
        border-top: 1px var(--wordgame-gray) solid;
      }
    }
  }

  .wordgame__options-wrapper {
    width: 100%;
    max-width: 100%;
  }

  .wordgame__options-collapse {
    display: flex;
  }

  .wordgame__options-language {
    display: flex;
    flex-direction: column;
  }

  .wordgame-top-category {
    display: block;
    position: fixed;
    top: 20px;
    left: 80px;
    z-index: 10;

    span {     
      color: black; 
      font-size: 1.25rem;
      font-weight: 700 !important;
    }
  }
}

.empty-card_for_print_fix {
  display: none;
  opacity: 0 !important;
  visibility: hidden !important;
}

@media print {
  :root {
    --wordgame-card-bottom-padding: 4px;
  }

  body.has-wordgame {

    background-color: white !important;

    @page {
      size: A3;
    }

    .empty-card_for_print_fix {
      display: block;
      opacity: 0 !important;
      visibility: hidden !important;
    }
    
    .wordgame__content {
      .wordgame__category--header {
        h2 {
          font-size: 2.5em;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
  
      .wordgame__subcategory--header {
        h3 {
          font-size: 2.25em;
          margin-top: 4px;
          margin-bottom: 0;
        }
      }

      > .subcategory-container {
        break-inside: auto;

        ~ .subcategory-container {
          break-inside: avoid;
        }
      }
    }

    .wordgame__col {
      display: inline-block;
      width: 33%;
      break-inside: avoid;
    }

    .wordgame__card {
      &__thumb {
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
        print-color-adjust: exact !important;

        padding-bottom: 115%;
      }

      &__name {
        font-size: 2em;
        padding: 0;
        min-height: auto;
        background: none;
      }
    }

    .card--type-2 {
      .card__button {
        display: none;
      }

      .card__face {
        z-index: 3;
      }
    }

    .wordgame-multicard,
    .wordgame-static-picture {
      width: 100vw !important;
      max-height: 90vh !important;

      svg,
      img {
        height: unset !important;
        max-height: 100% !important;
      }
    }

    .wordgame__row {
      display: block;
    }

    .container-fluid.header-container .row.header-wrap,
    footer.footer,
    .top-button#topUp {
      display: none;
    }
  }
}