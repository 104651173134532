.recommended-usage-label {
  position: relative;
  display: inline-block;
  padding: 10px 0 7px;
  font-size: $h4-font-size;
  font-style: normal;
  color: $blue-350;
  text-transform: uppercase;

  .label-text{
    position: relative;
    z-index: 1;
  }
}

