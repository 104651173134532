.header-container.show-header .header-links {
  display: flex;
}

.header-links {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zindex-modal;
  display: none;
  width: 20rem;
  height: 100%;
  padding: 24px 0;
  background-color: $gray-1000;
  flex-direction: column;

  &::before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: calc(100% - 320px);
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .7;
  }

  .menu-item {
    padding: 8px 24px;
    font-size: $h4-font-size;

    &:hover{
      color: $white;
      background: $gray-900;
    }
    &.selected {
      color: $white;
      background-color: $blue-300;
    }
    &:last-of-type {
      pointer-events: none;
      cursor: none;
    }
  }
}

