.btn-icon {
  @include button-variant(transparent, transparent, $hover-background: $gray-010, $hover-border: $gray-010, $active-background: $gray-100, $active-border: $gray-100);
  color: $gray-300;
  @include box-shadow(none);
}

.btn-primary,
.btn-success {
  box-shadow: none;
}


// Make a button look and behave like a link
.btn-link-dark {
  font-weight: $font-weight-normal;
  color: $body-color;
  text-decoration: underline;

  @include hover () {
    color: $gray-1000;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    text-decoration: none;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}

// Make a button look and behave like a link
.btn-link-muted {
  font-weight: $font-weight-bold;
  color: $gray-350;
  text-decoration: none;

  @include hover () {
    color: $gray-1000;
    text-decoration: underline;
  }

  &:focus,
  &.focus {
    text-decoration: underline;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}

/* stylelint-disable */
.feedback-btn {
  @include media-breakpoint-down(sm) {
    position: fixed;
    right: 8px;
    bottom: 8px;
    z-index: $zindex-dropdown;
  }
}

.survey-active {
  .feedback-btn {
    bottom: 80px;
  }

  .top-button {
    position: fixed;
    bottom: 160px;
    right: 20px;
    height: 42px;
    width: 42px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2742%27 height=%2742%27 fill=%27none%27%3E%3Cpath fill=%27%23173148%27 fill-rule=%27evenodd%27 d=%27M21 42c11.598 0 21-9.402 21-21S32.598 0 21 0 0 9.402 0 21s9.402 21 21 21Z%27 clip-rule=%27evenodd%27/%3E%3Cpath fill=%27%23fff%27 fill-rule=%27evenodd%27 d=%27M21.41 16c.108 0 .212.043.29.118l9 8.853c.159.156.158.41-.002.565a.416.416 0 0 1-.579-.002l-8.71-8.634-8.71 8.635a.416.416 0 0 1-.578.001.394.394 0 0 1-.002-.566l9-8.852a.414.414 0 0 1 .29-.118Z%27 clip-rule=%27evenodd%27/%3E%3C/svg%3E");
    border-radius: 100px;
  }
}

.btn-collapse {
  &.show {
    .collapse-icon {
      transform: rotate(180deg);
    }
  }

  .collapse-icon {
    transition: transform ease-in-out .32s;
  }

  &[aria-expanded=false] .see-less-content {
    display: none;
  }

  &[aria-expanded=true] {
    & .see-more-content {
      display: none;
    }

    & .see-less-content {
      display: block;
    }
  }
}

.btn-ellipsis {
  .collapse-icon {
    margin-bottom: 2px;
  }

  &.collapsing {
    text-decoration: none;
  }

  //repurpose  collapse plugin "show" class to toggle ellipsis plugin
  &.show {
    display: none !important;
  }
}

.btn-bold {
  font-size: 12px;
  font-weight: bold;
  color: $gray-350;

  .fa {
    margin-right: 8px;
  }
}