.logo {
  display: block;

  background-repeat: no-repeat;
  background-size: contain;

}

.logo-header {
  width: 48px;
  height: 42px;
  background-image: url("../images/sonaveeb-logo-sm.svg");
  &.logo-light{
    background-image: url("../images/sonaveeb-logo-light-sm.svg");
  }

  @include media-breakpoint-up(md) {
    width: 186px;
    height: 47px;
    background-image: url("../images/sonaveeb-logo-lg.svg");
    &.logo-light{
      width: 252px;
      height: 37px;
      background-image: url("../images/sonaveeb-logo-light-lg.svg");
    }

  }
}

.logo-big {
  display: block;
  width: 270px;
  height: 93px;
  margin: 0 auto;
  background-image: url("../images/sonaveeb-logo-lg.svg");
  background-repeat: no-repeat;
  background-size: contain;

  &.logo-big-light{
    background-image: url("../images/sonaveeb-logo-light-lg.svg");
  }

  @include media-breakpoint-up(md) {
    width: 372px;
    height: 93px;
    margin-bottom: 55px;
  }
}
