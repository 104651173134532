// Add Custom variables here
$gray-010: #f0f0f0;
$gray-100: #ecf0f2;
$gray-200: #ccd9e0;
$gray-300: #8a98a5;
$gray-350: #687887;
$gray-400: #5d606e;
$gray-500: #8996a4;
$gray-900: #3f3f3f;
$gray-1000: #0e1013;

$white: #fff;

$blue-100: #a0dcf8;
$blue-200: #5aabe0;
$blue-300: #2c6fb6;
$blue-350: #0073e6;
$blue-400: #005aa9;
$blue-900: #00377a;

$banner-blue-hover: #002ca1;
$banner-blue-bg: #000087;

$banner-gray: #fdfdfd;

$red-100: #ffb9b9;
$red-400: #ec0138;

$green-100: #a9eeb4;
$green-400: #00892e;
$green-700: #007025;

$yellow-100: #ffffe8;

$purple-100: #db8dff;

$blue: $blue-400;
$red: $red-400;
$green: $green-400;

$primary: $blue-300;
$success: $green-400;

$dark: $gray-1000;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$headings-font-weight: $font-weight-normal;

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Inter", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-open-sans: "Open Sans", -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// stylelint-enable value-keyword-case

$body-bg: $gray-010;
$body-color: $gray-900;


$font-size-base: 1rem; //16px // Assumes the browser default, typically `16px`
$font-size-md: $font-size-base * .875; //14px
$font-size-sm: $font-size-base * .75; //12px

$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.642;
$h3-font-size: $font-size-base * 1.428;
$h4-font-size: $font-size-base * 1.25; //20px
$h5-font-size: $font-size-base * 1.125; //18px
$h6-font-size: $font-size-base;


$enable-shadows: true;

$fa-font-path: "../fonts/fontawesome";

$border-color: $gray-200;
$link-color: $blue-300;
$input-btn-font-size-sm: $font-size-md;
$input-border-color: $gray-300;
$btn-disabled-opacity: .4;
$navbar-dark-color: $white;

//Image gallery styles
$sl-font-family: $font-family-sans-serif;
$sl-navigation-color: $gray-200;
$sl-overlay-background: $gray-1000;
$sl-overlay-opacity: .9;
$sl-caption-background: none;


$sl-counter-fontsize: $font-size-md;
$sl-caption-fontsize: $font-size-md;

$sl-close-fontsize: 2rem;

$sl-arrow-fontsize-small: 2rem;
$sl-arrow-fontsize-medium: 2rem;
$sl-arrow-fontsize-large: 2rem;

$sl-img-border-small: 6px solid $white;
$sl-img-border-medium: 12px solid $white;
$sl-img-border-large: $sl-img-border-medium;


:root {
  --color-gray-010: #{$gray-010};
  --color-gray-100: #{$gray-100};
  --color-gray-200: #{$gray-200};
  --color-gray-300: #{$gray-300};
  --color-gray-350: #{$gray-350};
  --color-gray-400: #{$gray-400};
  --color-gray-500: #{$gray-500};
  --color-gray-900: #{$gray-900};
  --color-gray-1000: #{$gray-1000};
  --color-light-gray: #C4D2DD;
  --color-bluish-gray: #E4ECEF;
  --color-white: #{$white};
  --color-blue-100: #{$blue-100};
  --color-blue-200: #{$blue-200};
  --color-blue-300: #{$blue-300};
  --color-blue-350: #{$blue-350};
  --color-blue-400: #{$blue-400};
  --color-blue-900: #{$blue-900};
  --color-light-blue: #D7E5F2;
  --color-dark-blue: #173148;
  --color-dark-blue-75: #173148BF;
  --color-red-100: #{$red-100};
  --color-red-400: #{$red-400};
  --color-green-100: #{$green-100};
  --color-green-400: #{$green-400};
  --color-green-700: #{$green-700};
  --color-yellow-100: #{$yellow-100};
  --color-purple-100: #{$purple-100};
  --color-orange: #DE6837;
}