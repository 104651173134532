// Homonym styles
/* stylelint-disable */
.homonym-item-wrap {
  display: block;
  transition: 0ms !important;
}

.homonym-matches,
.homonym-intro {
  line-height: 1.4;
  white-space: nowrap;
}

.homonym-name{
  padding: 0 12px;
}

.homonym-nr {
  position: relative;
  top: -.1875rem;
  display: inline-block;
  width: 1.375rem;
  height: 1.375rem;
  padding-top: .125rem;
  font-size: .75rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: #001124;
  border-radius: 6.25rem;
  transition: 0ms !important;
}

.homonym-matches,
.homonym-intro {
  font-size: .75rem;
}

.homonym-matches {
  font-weight: 700;
}

.homonym-intro * {
  color: #7f8891 !important;
}

.homonym-panel {
  @include media-breakpoint-up(md) {
    overflow: hidden;
    & + .word-details{
      margin-top: -1px;
      border-top: 1px solid $border-color;
    }
    &.overflow{
      position: relative;

      &::before,
      &::after{
        position: absolute;
        top: 0;
        z-index: 1;
        display: block;
        width: 24px;
        height: 100%;
        pointer-events: none;
        content: "";
        background: $black;

      }
      &::before{
        @include border-top-left-radius(10px);
        background: linear-gradient(90deg, #8a98a5 -25%, rgba(236, 240, 242, 0) 100%);
      }
      &::after{
        right: 0;
        @include border-top-right-radius(10px);
        background: linear-gradient(270deg, #8a98a5 -25%, rgba(236, 240, 242, 0) 100%);
      }

    }
    &.overflow-right-end{
      &::after{
        display: none;
      }
    }
    &.overflow-left-end{
      &::before{
        display: none;
      }
    }
  }

}

.homonym-list-toggle {
  position: relative;
  width: 100%;
  padding: 9px 42px 9px 16px;
  margin-bottom: 24px;
  background: $white;
  border: 3px solid $gray-300;
  height: 100%;
  
  @include border-radius(10px);

  &::after{
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: $gray-100;
    background: $gray-300;
    height: 100%;
    content: "\f0d7";
    top: 0;
    right: 0;
    width: 44px;
    background: #8a98a5;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .homonym-item-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding-right: 16px;
  }
  @include form-control-focus();
}

.homonym-list {
  background: $white;
  @include media-breakpoint-down(sm) {

    display: none;
    margin-top: -24px;
    border: 3px solid $gray-300;
    @include border-radius(10px);
    &.expand {
      display: block;
    }

    .homonym-list-item:first-child{
      @include border-top-radius(7px);
    }

    .homonym-list-item:last-child{
      @include border-bottom-radius(7px);
    }

  }
  @include media-breakpoint-up(md) {
    display: flex;
    margin-bottom: -15px;
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    background: none;
  }
  .homonym-list-item:first-child .homonym-item{
    @include border-top-left-radius(10px);
  }
  .homonym-list-item:last-child .homonym-item {
    @include media-breakpoint-down(sm){
      border-bottom: 0;
    }
  }

}

.homonym-list-item {
  @include media-breakpoint-down(sm) {

    &.selected {
      background: $blue-100;
      .homonym-item,
      .homonym-name{
        color: $gray-1000;
      }
    }
  }

  &.selected {
    .homonym-item {
      @include media-breakpoint-up(md) {
        background: $white;
        border-bottom-color: $white;
      }
    }
  }
  &:not(.selected){
    @include media-breakpoint-up(md){
      .lang-code{
        background: $white;
      }
    }
  }
}

.homonym-item {
  position: relative;
  display: block;
  padding: 1.25rem;
  padding-right: 3.75rem;
  overflow: hidden;
  border-bottom: 1px solid $border-color;

  @include media-breakpoint-up(md) {
    position: relative;
    display: flex;
    height: 100%;
    padding: 0 0 15px;
    background: $gray-100;
    border-right: 1px solid $gray-200;
  }

  &:hover {
    text-decoration: none;
  }

  .homonym-name-container {
    @include media-breakpoint-up(md) {
      position: relative;
      display: inline-block;
      max-width: calc(100% - 24px);
      padding-right: .375rem;
    }
    .homonym-name{
      padding-left: 4px;
    }
  }

  .homonym-nr {
    @include media-breakpoint-up(md) {
      display: inline-block;
      color: $white;
    }
  }

  .homonym-item-wrap {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      width: 15rem !important;
      padding: 12px 16px;
      margin-bottom: 0;
      color: #001124;
      text-overflow: ellipsis;
      transition: 0ms !important;
    }
  }

  .homonym-header {
    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
  }

  * {
    @include media-breakpoint-up(md) {
      @include text-truncate();
    }

  }
}


.word-details-homonym-nr {
  @include media-breakpoint-up(md) {
    position: relative;
    top: -.25rem;
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    font-weight: 700;
    line-height: 1.6;
    color: $white;
    text-align: center;
    border-radius: 6.25rem;
    transition: 0ms !important;
  }

}


.homonym-intro {
  @include media-breakpoint-up(md) {

    max-height: 1.5rem;
    margin-top: auto;
  }
}

.homonym-matches {
  @include media-breakpoint-up(md) {
    margin-bottom: auto;
  }

}


@media (max-width: 767px) {

  .homonym-item *:not(.homonym-nr) {
    display: flex;
    align-items: center;
    float: left;
    white-space: nowrap;
  }
  .homonym-item-wrap {
    max-width: 100%;
  }
  .homonym-matches,
  .homonym-intro {
    max-width: 100%;
    @include text-truncate();
  }
  .homonym-nr {
    top: -.0625rem;
    padding-top: .125rem;
    margin: 0 .625rem;
  }
  .homonym-matches {
    flex-shrink: 0;
    margin-right: .125rem;
    font-size: 1rem;
    font-weight: $font-weight-bold;
  }

}

.is-homonym sup{
  left: -5px;
}
