@include media-breakpoint-up(md){
  .meaning-panel {
    padding: 5px;
    overflow: auto;
  }
}
.meaning-sub-heading {
  padding: 10px 16px;
  margin-bottom: 0;
  text-transform: uppercase;
  background: $gray-200;
  border-bottom: 1px solid $gray-200;
}
.meaning-meta{
  padding-bottom: 8px;
}
