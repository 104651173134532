.morphology-section{
  &.collapse{
    max-height: 155px;
    overflow: hidden;
  }
}
.morph-word {
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recommend-btn {
  position: relative;
  z-index: 0;
  display: inline-block;
  height: 44px;
  padding: 0 22px;
  margin: 0 0 13px 20px;
  font-size: 20px;
  line-height: 44px;
  color: #fff;
  text-transform: uppercase;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    content: " ";
    background: $blue-350;
    transform: skewX(-10deg);
  }
}

.paradigm-comment {
  margin: 13px 0;
  font-size: smaller;
}

.morphology-paradigm {
  hr {
    margin: 10px -10px 0;
  }
  & + .paradigm-comment {
    margin-top: 30px;
  }
  table {
    min-width: 100%;
    td {
      min-width: 30%;
      max-width: 60%;
      white-space: normal;
      line-height: 1.4;
      div {
        padding: 2px;
        margin-right: 4px;
        display: inline-block;
      }
    }
  }
}
